<template>
  <fragment>
    <NavbarVue />
    <section class="container pt-10">
      <div class="hero-process">
        <div class="hero-process__cover">
          <!-- <img src="../assets/images/process.jpeg" alt="" /> -->
        </div>
        <div class="hero-process__about">
          <h1 class="display-5">
            Revolucionamos Tu Presencia Digital con FusionX©
          </h1>
          <p class="text-medium">
            En Kiiner Marketing, abrazamos la innovación a través de FusionX©,
            nuestro método distintivo para transformar tu presencia digital y
            llevar tu marca a nuevas alturas. Descubre cómo construimos campañas
            impactantes y servicios personalizados para impulsar tu éxito en el
            mundo digital.
          </p>
          <ul class="fusion-x-benefits">
            <li>
              <ion-icon name="bulb-outline"></ion-icon> Transformación de Clics
              en Clientes Reales
            </li>
            <li>
              <ion-icon name="search-outline"></ion-icon> Alcanza Directamente a
              tu Audiencia Clave
            </li>
            <li>
              <ion-icon name="megaphone-outline"></ion-icon> Construcción de
              Presencia Social Sólida
            </li>
            <li>
              <ion-icon name="color-palette-outline"></ion-icon> Cohesión y
              Atractivo en la Imagen de Marca
            </li>
            <li>
              <ion-icon name="desktop-outline"></ion-icon> Establecimiento
              Profesional de Presencia Digital
            </li>
            <li>
              <ion-icon name="camera-outline"></ion-icon> Aumento de
              Credibilidad con Fotografía de Calidad
            </li>
            <li>
              <ion-icon name="camera-outline"></ion-icon> Potenciación de tu
              Estrategia Online
            </li>
            <li>
              <ion-icon name="camera-outline"></ion-icon> Desarrollo Web a la
              Medida de tu Marca
            </li>
          </ul>
        </div>
      </div>
    </section>
    <FooterVue />
  </fragment>
</template>

<script>
import NavbarVue from "@/components/Navbar.vue";
import FooterVue from "@/components/Footer.vue";

export default {
  components: {
    NavbarVue,
    FooterVue,
  },
};
</script>
